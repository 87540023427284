(function ($) {
	$(document).ready(function() {
		// objectFitPolyfill();

		$(window).scroll(function () {
			var top = $(document).scrollTop();
			var header = $('.header');
			if (top === 0 && header.hasClass('header--scrolled')) {
				header.removeClass('header--scrolled');
			} else {
				if (!header.hasClass('header--scrolled')) {
					header.addClass('header--scrolled');
				}
			}
		});

		var searchButton = $('.header .search__toggle');
		var searchWrap = $('.header .search__wrapper');

		if (searchButton.length > 0) {
			searchButton.on('click', function (e) {
				e.preventDefault();
				if (searchWrap.hasClass('active')) {
					searchButton.parent('.header__search').removeClass('active');
					searchWrap.removeClass('active').slideUp(500);
				} else {
					searchButton.parent('.header__search').addClass('active');
					searchWrap.addClass('active').slideDown(500);
				}
			})
		}

		$('html').on('click', function(e) {
			if ($(e.target).closest('.search__wrapper, .search__toggle').length === 0) {
				if (searchWrap.hasClass('active')) {
					searchButton.parent('.header__search').removeClass('active');
					searchWrap.removeClass('active').slideUp(500)
				}
			}
		});

		//------------Mobile Menu ------------

		var mobileMenu = $('.header__navbar');
		var sandwich = $('.menu__toggle');
		var overlay = $('.site-page');

		function closeMenu() {
			sandwich.removeClass('menu__toggle--clicked');
			mobileMenu.removeClass('header__navbar--opened');
			overlay.removeClass('site-page--overlay');
			$('body').removeClass('overflow-for-mobile');
		}

		function openMobileMenu() {
			sandwich.on('click', function () {
				if (mobileMenu.hasClass('header__navbar--opened')) {
					closeMenu();
				} else {
					sandwich.addClass('menu__toggle--clicked');
					mobileMenu.addClass('header__navbar--opened');
					overlay.addClass('site-page--overlay');
					$('body').addClass('overflow-for-mobile');
				}
			});
		}

		openMobileMenu();

		$('html').on('click', function(e) {
			if ($(e.target).closest('.header__navbar, .menu__toggle').length === 0) {
				closeMenu();
			}
		});


		//----------------Home Banner Slider---------------

		var sliderBanner = $('.slider.slider--banner');

		if (sliderBanner.length > 0) {
			$(sliderBanner).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				dots: true,
				fade: false,
				infinite: true,
				useTransform: true,
				speed: 700,
				cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
			});
		}

		//---------------Programs Hover----------
		$('.two-columns--programs .btn').each(function () {
			$(this).on('mouseover focus', function(){
				$(this).parents('.two-columns__item--bg').addClass('is-hover');
			}).on('mouseout blur', function(){
				$(this).parents('.two-columns__item--bg').removeClass('is-hover');
			})
		})

		//-------Content Padding for 404 -------

		function content() {
			var sectionWithContent = $('.section--not-found');
			var windowHeight = $(window).outerHeight();
			var contentHeight = sectionWithContent.find('.container').outerHeight();
			var footerHeight = $('footer.footer').outerHeight();
			var paddingTop = Math.round((windowHeight - contentHeight - footerHeight) / 2);

			if (paddingTop >= 50) {
				sectionWithContent.css('padding-top', paddingTop + 'px');
				sectionWithContent.css('padding-bottom', paddingTop + 'px');
			}
		}
		content();

		$(window).resize(function () {
			content();
		})

		//-------Content Min Height -------

		function contentMinHeight() {
			var siteContent = $('.site-content');
			var windowHeight = $(window).outerHeight();
			var footerHeight = $('footer.footer').outerHeight();
			var minHeight = (windowHeight - footerHeight);

			if (minHeight >= 50) {
				siteContent.css('min-height', minHeight + 'px');
			}
		}
		contentMinHeight();

		$(window).on('resize', function () {
			contentMinHeight();
		})

		//------------ Subscribe Form--------
		var form = $('.form--subscribe');
		var submitButton = form.find('button[type=submit]');
		var emailInput = form.find('input[type="email"]');

		function validateEmail (valEmail) {
			return new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(valEmail)
		}

		function disableSubmit() {
			if (emailInput.val() !== ''){
				if(!validateEmail(emailInput.val())) {
					emailInput.removeClass("valid").addClass("invalid");
					emailInput.on('input', function () {
						if (emailInput.val() !== '' && validateEmail(emailInput.val())) {
							emailInput.removeClass("invalid").addClass("valid");
							form.find('.form__error--email').html('');
						}
					});
				}
			} else {
				emailInput.removeClass("valid").addClass("invalid");
			}
		}

		if (submitButton.length > 0) {
			submitButton.on('click', function () {
				disableSubmit();
			})
			form.on('submit', function (e) {
				if (!validateEmail(emailInput.val())) {
					emailInput.removeClass("valid").addClass("invalid");
					form.find('.form__error--email').html('Enter a valid email');
					return false;
				} else {
					emailInput.removeClass("invalid").addClass("valid");
					form.find('.form__error--email').html('');
					return true;
				}
			})
		}

		//-------------Gallery PopUp and Slider---------

		$('.wp-block-gallery').each(function () {
			$(this).magnificPopup({
				delegate: 'a',
				type: 'image',
				gallery: {
					enabled: true,
				}
			})
		})

		//-------------Events----------

		function prevent(){
			$('.hide-adv, .show-reg').on('click', function(event){
				event.preventDefault();
			});
		}
		prevent();

		// for Search Event
		$('.hide-adv').on('click', function() {
			var hideBlock = $('.event-search-bottom-box');
			$(this).text(hideBlock.is(':visible') ? 'Show Advanced Search' : 'Hide Advanced Search');
			$(this).toggleClass('active').parent().siblings('.event-search-wrap').find(hideBlock).slideToggle(500);
		});

		//for registration
		$('.show-reg').on('click', function() {
			var btn = $(this),
				box = $('.event-registration-box.hide'),
				scrollID = $('#reg-scroll');

			box.slideDown(400);
			$('.show-reg').hide(0);
			if (scrollID.length) {
				$('html, body').animate({
					scrollTop: scrollID.offset().top - 100
				}, 800);
			}
		});

		//---Scroll top on Form submit

		var box = $('.event-registration-box');
		var emForm = box.find('form.em-booking-form');

		if (emForm.length > 0) {
			emForm.on('ajaxComplete', function () {
				$('html, body').animate({
					scrollTop: box.offset().top - 100
				}, 800);
			})
		}

		//--------------Tabs Take Action--------------

		//-----Main Tabs Navigation--------

		var tabs = $('.tabs');

		if (tabs.length > 0) {
			var nav_items = tabs.find('.tabs__nav-item');

			nav_items.each(function () {
				$(this).on('click', 'a[href^="#"]', function (e) {
					e.preventDefault();
					$(this).addClass('active');
					nav_items.find('a').not($(this)).removeClass('active');

					var tabid = $(this).attr("data-tab-id");

					var content = $(this).parents('.tabs').find('.tabs__content').find(".tabs__content-item[data-tab-id='" + tabid + "']");
					var content_id = content.attr('data-tab-id');

					if (content_id === tabid) {
						content.addClass('active').fadeIn(500);
						$('.tabs__content .tabs__content-item').not(content).removeClass('active').fadeOut(300);
					}
				});
			})
		}

		//-----SubTabs Navigation--------

		function scrollTo(el) {
			if (el.length > 0) {
				var sectionData = $(el).attr('data-subtab-id');
				var subContent = $(el).parents('.tabs').find(".tabs__sub-content[data-subtab-id='" + sectionData + "']");
				var el_top = $(subContent).offset().top - 150;
				setTimeout(function () {
					$("html, body").animate({scrollTop: el_top}, 700);
				}, 300);
			}
		}

		$(window).on('load', function () {
			var hash = window.location.hash;

			if (hash.length > 0) {
				subnav_items.each(function () {
					var link = $(this).find('a[href^="#"]');
					if (link.attr('href') === hash) {
						link.addClass('active');
						$('.tabs__sub-nav .tabs__sub-item a').not(link).removeClass('active');

						var contentId = link.attr('data-subtab-id');
						var subContent = $(this).parents('.tabs').find(".tabs__sub-content[data-subtab-id='" + contentId + "']");
						subContent.addClass('active').fadeIn(500);
						$('.tabs__sub-content').not(subContent).removeClass('active').fadeOut(300);
					}
				});
			}
		});

		if (tabs.length > 0) {
			var subnav_items = tabs.find('.tabs__sub-nav .tabs__sub-item');

			subnav_items.each(function () {
				$(this).on('click', 'a[href^="#"]', function (e) {
					window.location.hash = $(this).attr("href");
					e.preventDefault();
					$(this).addClass('active');
					subnav_items.find('a').not($(this)).removeClass('active');

					var subTabId = $(this).attr("data-subtab-id");
					var subContent = $(this).parents('.tabs').find(".tabs__sub-content[data-subtab-id='" + subTabId + "']");
					var content_id = subContent.attr('data-subtab-id');

					if (content_id === subTabId) {
						subContent.addClass('active').fadeIn(500);
						$('.tabs__sub-content').not(subContent).removeClass('active').fadeOut(300);
					}
					scrollTo($(this));
				});
			});
		}

		//---------------Archive Links Hover----------
		$('.archive__loop-item.article .article__title').each(function () {
			$(this).on('mouseover focus', function(){
				$(this).parents('.archive__loop-item.article').addClass('is-hover');
				$(this).parents('.archive__loop-item.article').find('.article__thumbnail a').addClass('is-hover');
			}).on('mouseout blur', function(){
				$(this).parents('.archive__loop-item.article').removeClass('is-hover');
				$(this).parents('.archive__loop-item.article').find('.article__thumbnail a').removeClass('is-hover');
			})
		})

		$('.archive__loop-item.article .article__thumbnail a').each(function () {
			$(this).on('mouseover focus', function(){
				$(this).parents('.archive__loop-item.article').addClass('is-hover');
			}).on('mouseout blur', function(){
				$(this).parents('.archive__loop-item.article').removeClass('is-hover');
			})
		});


		//---------- Archives Sidebar---------

		var pagesListWrap = $('.pages-list__wrap');
		var pagesListTitle = pagesListWrap.find('.pages-list__title');

		function categoriesScroll() {

			var top = $(document).scrollTop();
			var pageList = pagesListWrap.find('.pages-list');
			var pageListHeight = pageList.outerHeight();
			var sidebarTop;

			if($('body').hasClass('logged-in')) {
				if ($(window).width() >= 601) {
					sidebarTop = pagesListWrap.offset().top - 126;
				} else {
					sidebarTop = pagesListWrap.offset().top - 80;
				}
			} else {
				sidebarTop = pagesListWrap.offset().top - 80;
			}

			if ($(window).width() <= 767) {
				if (sidebarTop <= top) {
					pagesListWrap.css('height', pageListHeight).addClass('is-sticky');
				} else {
					pagesListWrap.css('height', pageListHeight).removeClass('is-sticky');
				}
			} else {
				pagesListWrap.css('height', '100%').removeClass('is-sticky');
			}
		}

		pagesListTitle.on('click', function () {
			var internalPagesList = pagesListWrap.find('.pages-list__list');
			if (internalPagesList.hasClass('active')) {
				$(this).removeClass('active');
				internalPagesList.removeClass('active').slideUp(500);
			} else {
				internalPagesList.addClass('active').slideDown(500);
				$(this).addClass('active');
			}
		})

		if (pagesListWrap.length > 0) {
			categoriesScroll();

			$(window).load(function () {
				categoriesScroll();
			})

			$(window).scroll(function () {
				categoriesScroll();
			});

			$(window).resize(function () {
				categoriesScroll();
			});
		}


		//----------------Stay Connected YouTube Slider---------------

		var youtubeSliderBanner = $('.slider.slider--youtube');

		if (youtubeSliderBanner.length > 0) {
			$(youtubeSliderBanner).slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: true,
				dots: false,
				fade: false,
				infinite: false,
				useTransform: true,
				speed: 700,
				cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
						}
					},
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						}
					},
					{
						breakpoint: 576,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
						}
					},
				]
			});
		}

	});
})(jQuery);

